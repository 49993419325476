<template>
  <div class="bg-default">
    <header class="header">
      <div class="header-left">
        <a href="#">
          <img
            class="header-settings-icon"
            src="img/settings-icon.png"
            alt="Settings"
          />
        </a>
      </div>
      <div class="header-center"></div>
      <div class="header-right">
        <a @click="exit()">
          <img
            class="header-settings-icon"
            src="img/exit-icon.png"
            alt="Exit"
          />
        </a>
      </div>
    </header>
    <main class="main">
      <div class="container">
        <div class="card card-centerred">
          <div class="card-icon card-icon-circle mb-48">
            <img src="img/hourglass-icon.png" alt="Hourglass icon" />
          </div>
          <div class="card-title mb-64">
            Hamarosan<br />
            kezdünk!
          </div>
          <div class="card-info">
            A kísérlet akkor indulhat, ha a csoportból mindenki készen áll.
          </div>
        </div>
      </div>
    </main>
    <footer class="footer"></footer>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "GroupStart",
  computed: {
    ...mapGetters(["status", "stopGameTurnId", "actualGameTurnId"]),
  },
  methods: {
    goNext() {
      this.$store.dispatch("storeGameplayEvent", {
        round: 0,
        event: "video_1",
      });
      this.$router.push("waitingcounter");
    },
  },
  watch: {
    stopGameTurnId(newTurn, oldTurn) {
      if (newTurn !== this.actualGameTurnId) this.goNext();
    },
  },
  created() {
    console.log("GROUPSTART CREATED");
    console.log("stopGameTurn", this.stopGameTurnId);
    console.log("actualGameTurn", this.actualGameTurnId);
    if(this.stopGameTurnId === null) return;
    if (this.stopGameTurnId !== this.actualGameTurnId) {
      this.goNext();
    }
  },
  mounted() {
    console.log("allow", this.stopGameTurnId);
    console.log("actual", this.actualGameTurnId);
    //this.$store.dispatch('storeGameplayEvent', {round: 0,event: "start"});
  },
};
</script>

<style>
</style>